import React from 'react'
import { api } from 'store/Base'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { Body3, Caption1 } from 'component/PerformanceStyles';
import moment from 'moment'
import styled from 'styled-components'
import { LabelDot } from '@code-yellow/spider'
import { ContentContainer } from 're-cy-cle'
import { Popup } from 'semantic-ui-react';
import { StyledIcon, StyledLabel } from '../../component/Labels';


///// Relations

export const BASE_RELATIONS = [
  'articleType.classification',

  'processVersion.batchType.articleType.storageLocations.warehouse',
  'processVersion.batchType.articleType.warehouses.warehouse',
  'processVersion.steps.workStation.productionLineVersion.productionLine',
  'processVersion.steps.nextStep',
  'batches.lastStep',
  'batches.batchType',
  'inShipment.purchaseOrders.warehouse',
  'inShipment.purchaseOrders.supplier',
  'inShipment.purchaseOrders.exactPurchaseOrder',
  'inShipment.purchaseOrders.exactGlobePurchaseOrder',

  'outShipment.salesOrder.warehouse',
  'outShipment.salesOrder.customer',
  'outShipment.salesOrder.exactSalesOrder',
  'outShipment.salesOrder.exactGlobeSalesOrder',

  'warehouseTransfer',

  'productionOrder.warehouse',
  'productionOrder.materialPlan.items.articleType.storageLocations.storageLocation',
  'productionOrder.materialPlan.items.articleType.storageLocations.warehouse',
  'productionOrder.materialPlan.items.articleType.warehouses.defaultStorageLocation',
  'productionOrder.purchaseOrderLines.purchaseOrder.exactGlobePurchaseOrder',
  'productionOrder.purchaseOrders.exactGlobePurchaseOrder',
  'productionOrder.salesOrderLine.salesOrder',
  'productionOrder.salesOrderLine.salesOrder.exactSalesOrder',
  'productionOrder.salesOrderLine.salesOrder.exactGlobeSalesOrder',
  'productionOrder.salesOrderLine.salesOrder.unit4Order',
  'productionOrder.salesOrderLine.salesOrder.navisionSalesOrder',
  'productionOrder.exactGlobeProductionOrder.exactGlobeSalesOrderLines.header',
  'productionOrder.exactGlobeProductionOrder.exactGlobeSalesOrderLines.salesOrderLine.salesOrder',
  'productionOrder.exactShopOrder.exactParentShopOrder',

  'stockCount.warehouse',
  'productionOrder.resourceAllocations.salesOrderLine.salesOrder.exactSalesOrder',
  'productionOrder.resourceAllocations.salesOrderLine.salesOrder.exactGlobeSalesOrder',
  'productionOrder.resourceAllocations.salesOrderLine.articleType',
]


/// Consts

export const MONTH = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}


///// Styling

export const COLORS = {
  grey50: '#FAFAFA',
  grey300: '#E0E0E0',
  grey800: '#424242',
  warning: '#FFF6E0',
  notWarning: '#FFF',
}

export const STATUS_ICONS = {
  open: 'circle outline',
  released: 'dot circle outline',
  done: 'check circle outline',
}

export const BigDot = styled(LabelDot)`
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.25rem;
  margin-left: 0rem;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
`;

export const FullContent = styled(ContentContainer)`
  main {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
  }
  display: flex;
  align-items: flex-start;

  .ui.table {
    border-style: solid;
    border-collapse: collapse;
    tbody > tr.groupMember {
      border-left: 6px solid ${COLORS.grey800};
      border-right: 6px solid ${COLORS.grey800};
    }
    tbody > tr.borderMember {
      border-bottom: 6px solid ${COLORS.grey800};
    }
  }
`

export function getCellBackgroundColor(productionRequest, period) {
  return isProductionRequestOverdue(productionRequest, period) ? COLORS.warning : COLORS.notWarning
}

export function isProductionRequestOverdue(productionRequest, period) {
  return productionRequest.period !== periodToBackend(period)
}

///// Order information

function getSalesOrderLines(resourceAllocations) {
  const salesOrderLines = []

  resourceAllocations.models.forEach(resourceAllocation => salesOrderLines.push(resourceAllocation.salesOrderLine))

  salesOrderLines.sort((l, r) => l.id - r.id)

  for (let i = 1; i < salesOrderLines.length; i++) {
    if (salesOrderLines[i].id === salesOrderLines[i - 1].id) {
      salesOrderLines.splice(i--, 1)
    }
  }

  return salesOrderLines
}

export function getERPShopORderTags(productionRequest) {
  const productionOrder = productionRequest.productionOrder
  const exactShopOrder = productionOrder.exactShopOrder
  const exactGlobeProductionOrder = productionOrder.exactGlobeProductionOrder

  return (
    <>
      {!exactGlobeProductionOrder.isNew && exactGlobeProductionOrder.getLink()}
      {!exactShopOrder.isNew && exactShopOrder.getLink()}
      {!exactShopOrder.exactParentShopOrder.isNew && exactShopOrder.getParentLink()}
    </>
  )
}

export function getOrderTags(productionRequest) {
  const purchaseOrders = productionRequest.inShipment.purchaseOrders
  const exactPurchaseOrders = purchaseOrders.map((po) => po.exactPurchaseOrder)
  const exactGlobePurchaseOrders = purchaseOrders.map((po) => po.exactGlobePurchaseOrder)

  const salesOrder = productionRequest.outShipment.salesOrder
  const exactSalesOrder = salesOrder.exactSalesOrder
  const exactGlobeSalesOrder = salesOrder.exactGlobeSalesOrder

  const transfer = productionRequest.warehouseTransfer

  const productionOrder = productionRequest.productionOrder

  const stockCount = productionRequest.stockCount

  return (
    <>
      {!productionOrder.isNew && productionOrder.getLink()}
      {getERPShopORderTags(productionRequest)}
      {!stockCount.isNew && stockCount.getLink()}
      {!transfer.isNew && transfer.getLink()}
      {purchaseOrders.map((purchaseOrder) => !purchaseOrder.isNew && purchaseOrder.getLink())}
      {exactPurchaseOrders.map((exactPurchaseOrder) => !exactPurchaseOrder.isNew && exactPurchaseOrder.getLink())}
      {exactGlobePurchaseOrders.map((exactGlobePurchaseOrder) => !exactGlobePurchaseOrder.isNew && exactGlobePurchaseOrder.getLink())}
      {!salesOrder.isNew && salesOrder.getLink()}
      {!exactSalesOrder.isNew && exactSalesOrder.getLink()}
      {!exactGlobeSalesOrder.isNew && exactGlobeSalesOrder.getLink()}
      {!productionRequest.articleType.isNew && productionRequest.articleType.getLink()}
    </>
  )
}

export function getLinkedSalesOrdersTags(productionOrder) {
  const linkedSalesOrderLine = productionOrder.salesOrderLine

  const exactGlobeProductionOrder = productionOrder.exactGlobeProductionOrder
  const exactGlobeSalesOrderLines = exactGlobeProductionOrder.exactGlobeSalesOrderLines

  let exists = false
  exactGlobeSalesOrderLines.forEach(saLine => {
    if (saLine.salesOrderLine.id === linkedSalesOrderLine.id) {
      exists = true
    }
  })

  const salesOrderLinesTags = exactGlobeSalesOrderLines.map(saLine => !saLine.header.isNew
    ? <>{saLine.header.getLink()} / {saLine.lineNumber}<br /></>
    : <>{saLine.salesOrderLine.salesOrder.getLink()}{saLine.salesOrderLine.salesOrder.getErpLinks()} / {saLine.number}<br /></>
  )

  let salesOrderLinesTag = []
  if (!exists && !linkedSalesOrderLine.isNew) {
    salesOrderLinesTag = [<>{linkedSalesOrderLine.salesOrder.getLink()}{linkedSalesOrderLine.salesOrder.getErpLinks()} / {linkedSalesOrderLine.number}<br /></>]
  }

  let allocations = []
  if (isFeatureFlagEnabled('allocations') && productionOrder.resourceAllocations.length > 0) {
    allocations = getSalesOrderLines(productionOrder.resourceAllocations).map((line) => line.salesOrder.getIdLabel(true))
  }

  const tags = [].concat(salesOrderLinesTags, salesOrderLinesTag, allocations)

  let content = ''
  if (tags.length === 1) {
    content = tags[0]
  } else if (tags.length > 1) {
    content = <>
      <Popup data-test-linked-sales-order-popup hoverable
        trigger={
          <StyledLabel
            data-test-linked-orders-for-production-order={productionOrder.id}
            color={'red'}
            style={{ textDecoration: 'none', margin: '0' }}
            target={'_blank'}
          >
            <StyledIcon name="chain" />
          </StyledLabel>
        }
      >
        {tags}
      </Popup>
    </>
  }

  return (
    <>
      {content}
    </>
  )
}

export function getLinkedOrdersTags(productionOrder) {
  const exactGlobeProductionOrder = productionOrder.exactGlobeProductionOrder

  const purchaseOrders = productionOrder.purchaseOrders
  const purchaseOrderLines = productionOrder.purchaseOrderLines
  const salesOrderLines = exactGlobeProductionOrder.exactGlobeSalesOrderLines
  const linkedSalesOrderLine = productionOrder.salesOrderLine

  let exists = false
  salesOrderLines.forEach(saLine => {
    if (saLine.salesOrderLine.id === linkedSalesOrderLine.id) {
      exists = true
    }
  })


  const purchaseOrdersTags = purchaseOrders.map(pu =>
    <>{!pu.exactGlobePurchaseOrder.isNew && pu.exactGlobePurchaseOrder.getLink()}<br /></>
  )

  const purchaseOrderLineTags = purchaseOrderLines.map(puLine => {
    if (!puLine.purchaseOrder.exactGlobePurchaseOrder.isNew) {
      return (<>{puLine.purchaseOrder.exactGlobePurchaseOrder.getLink()} / {puLine.number}<br /></>)
    } else {
      return (<>{puLine.purchaseOrder.getLink()} / {puLine.number}<br /></>)
    }
  })

  const salesOrderLinesTags = salesOrderLines.map(saLine => !saLine.header.isNew
    ? <>{saLine.header.getLink()} / {saLine.lineNumber}<br /></>
    : <>{saLine.salesOrderLine.salesOrder.getLink()} / {saLine.number}<br /></>
  )

  let salesOrderLinesTag = []
  if (!exists && !linkedSalesOrderLine.isNew) {
    salesOrderLinesTag = [<>{linkedSalesOrderLine.salesOrder.getLink()} / {linkedSalesOrderLine.number}<br /></>]
  }

  const tags = purchaseOrderLineTags.concat(purchaseOrdersTags, salesOrderLinesTags, salesOrderLinesTag)
  const displayPopup = tags.length > 0;
  return (
    <>
      {purchaseOrders.map(pu =>
        <>{pu.getLink()}</>
      )}
      {displayPopup && (
        <Popup
          hoverable
          trigger={<StyledLabel
            data-test-linked-orders-for-production-order={productionOrder.id}
            color={'red'}
            style={{ textDecoration: 'none', margin: '0' }}
            target={'_blank'}
          >
            <StyledIcon name="chain" />
          </StyledLabel>
          }>
          {tags}
        </Popup>
      )}
    </>
  )
}


function getArticleTypeName(productionRequest) {
  const articleType = productionRequest.articleType

  let description = null
  if (isFeatureFlagEnabled('allocations') && !productionRequest.productionOrder.isNew && productionRequest.productionOrder.resourceAllocations.length > 0) {
    description = (
      getSalesOrderLines(productionRequest.productionOrder.resourceAllocations)
        .find(line => line.articleType?.id === articleType.id && line.description !== null && line.description.length > 0)?.description
    )
  } else if (!productionRequest.productionOrder.isNew) {
    description = productionRequest.productionOrder.description
  }

  return description !== null ? description : '';
}

export function getOrderInformation(productionRequest, showHeader = true, showDescription = true) {
  const purchaseOrders = productionRequest.inShipment.purchaseOrders
  const salesOrder = productionRequest.outShipment.salesOrder
  const transfer = productionRequest.warehouseTransfer

  const description = getArticleTypeName(productionRequest)

  return (
    <div data-test-order-information={productionRequest.id}>
      {showHeader &&
        <>
          <Body3>{!productionRequest.articleType.isNew && productionRequest.articleType.name}</Body3>
          <Body3>
            {purchaseOrders.map((purchaseOrder) => !purchaseOrder.isNew && !purchaseOrder.supplier.isNew && purchaseOrder.supplier.name)}
            {!salesOrder.isNew && !salesOrder.customer.isNew && salesOrder.customer.name}
          </Body3>
        </>}
      {showDescription &&
        <Caption1>
          {description}
          {purchaseOrders.map((purchaseOrder) => !purchaseOrder.isNew ? ' | ' + purchaseOrder.reference : '')}
          {!salesOrder.isNew ? ' | ' + salesOrder.reference : ''}
          {!transfer.isNew ? ' | ' + transfer.description : ''}
          {productionRequest.manualFinishedReason ? ' | ' + productionRequest.manualFinishedReason : ''}
        </Caption1>
      }
    </div>
  )
}


export async function getWorkStationsFte(productionRequests, period) {
  const allWsBookedHours = {}

  productionRequests.forEach(productionRequest =>
    productionRequest.processVersion.steps.models.forEach(step => allWsBookedHours[step.workStation.id] = 0)
  )

  const work = await api.get('work_station/fte_workload/', { period: periodToBackend(period) })

  Object.keys(work).forEach(ws => {
    if (allWsBookedHours[ws] !== undefined) {
      allWsBookedHours[ws] += work[ws] / 60
      allWsBookedHours[ws] /= 8 // TODO: Hardcoded value of the production line opening hours
    }
  })

  return allWsBookedHours
}


//// Period parsers

export function periodToBackend(period) {
  if (!period) {
    return null;
  }
  if (window.viewStore.progressScope === 'week') {
    return `${period.year}-W${period.week}`
  } else if (window.viewStore.progressScope === 'month') {
    return `${period.year}-${period.month}`
  } else if (window.viewStore.progressScope === 'none') {
    return
  } else {
    throw new Error(`unknown progress scope: ${window.viewStore.progressScope}`)
  }
}


export function isPeriodInFuture(period) {
  if (!period) {
    return null
  }

  const now = moment()
  return period.year > now.isoWeekYear() || (period.week > now.isoWeek() && now.isoWeekYear() === period.year)
}


/**
 * Returns the period for the backend in a format that the store.param['.period:in'] will understand
 * @param period
 */
export function periodLookBackToBackend(period) {
  if (!period) {
    return null
  }

  const LOOKBACK = 2    // In years
  const YEAR_WEEKS = 52
  const YEAR_MONTHS = 12

  // Work backwards until you get all weeks/months in that 2 year period
  if (window.viewStore.progressScope === 'week') {
    const periods = []
    let weeksBack = 0
    let year = period.year
    let week = period.week
    while (weeksBack < LOOKBACK * YEAR_WEEKS) {
      periods.push(`${year}-W${week}`)
      weeksBack++
      week--
      if (week <= 0) {
        week = YEAR_WEEKS
        year--
      }
    }

    return periods.join(',')
  } else if (window.viewStore.progressScope === 'month') {
    const periods = []
    let monthsBack = 0
    let year = period.year
    let month = period.month
    while (monthsBack < LOOKBACK * YEAR_MONTHS) {
      periods.push(`${year}-${month}`)
      monthsBack++
      month--
      if (month <= 0) {
        month = YEAR_MONTHS
        year--
      }
    }

    return periods.join(',')
  } else if (window.viewStore.progressScope === 'none') {
    return
  } else {
    throw new Error(`unknown progress scope: ${window.viewStore.progressScope}`)
  }
}

const WEEK_RE = /(\d+)-W(\d+)/
const MONTH_RE = /(\d+)-(\d+)/

export function formatPeriod(period) {
  if (period === null) {
    return null
  }
  if (window.viewStore.progressScope === 'none') {
    if (MONTH_RE.exec(period)) {
      const [year, month] = period.split('-')
      return `${MONTH[month]} ${year}`
    }
    if (WEEK_RE.exec(period)) {
      const [year, week] = period.split('-')
      return `${week} ${year}`
    }
  }
  if (window.viewStore.progressScope === 'month') {
    const [year, month] = period.split('-')
    return `${MONTH[month]} ${year}`
  } else if (window.viewStore.progressScope === 'week') {
    const [year, week] = period.split('-')
    return `${week} ${year}`
  } else {
    return null
  }
}

export function getPeriodMaxDate(period) {
  if (window.viewStore.progressScope === 'week') {
    const minDate = moment().isoWeekYear(period.year).isoWeekday(1).isoWeek(period.week).startOf('day')
    return minDate.clone().endOf('isoWeek')
  } else if (window.viewStore.progressScope === 'month') {
    const minDate = moment({ year: period.year, month: period.month - 1, day: 1 })
    return moment(minDate).endOf('month')
  } else {
    return null
  }
}


export function getMaxDates(pus, puLines) {
  let receiptDates = []
  if (pus.length > 0 && pus.filter(pu => pu.receiptDate !== null).length > 0) {
    receiptDates.push(...pus.filter(pu => pu.receiptDate !== null).map(pu => pu.receiptDate))
  }
  if (puLines.length > 0 && puLines.filter(puLine => puLine.receiptDate !== null).length > 0) {
    receiptDates.push(...puLines.filter(puLine => puLine.receiptDate !== null).map(puLine => puLine.receiptDate))
  }
  return receiptDates.length > 0 ? moment.max(...receiptDates) : null
}
